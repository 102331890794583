.homepage {
  height: 100vh;
  width: 100vw;
  background-image: url('../images/login/homepage.jpg');
}


.customerDetails {
  position: absolute;
  margin-top: -38px;
  margin-left: -px;
  background-image: none;
  float: left;
  z-index: 1;
}

.customerDetailsMessage {
  position: absolute;
  margin-top: -82px;
  margin-left: -px;
  font-size: 13px;
  color: red;
  background-image: none;
  float: left;
  z-index: 1;
}

.customerDetailsLoadingMessage {
  position: absolute;
  margin-top: -82px;
  margin-left: -px;
  font-size: 13px;
  color: #cddacf;
  background-image: none;
  float: left;
  z-index: 1;
}

.login {
  background: #cddacf;
  border: 3px solid #585A5C;
  margin-top: 15%;
  background-image: none;
  float: right;
  width: 30%;
  margin-right: 10%;
}

.login h2 {
  color: #343A40;
  margin-left: 38%;
  width: fit-content;
  padding: 10px;

}

.loginComp {
  padding: 5px 5px 2px 65px;
  background-color: #cddacf;

}

.borderItem {
  border: 1px solid #000000;
}

.loginComp label {
  font-size: 15px;
  font-weight: lighter;
  color: #16191d;
  width: 100%;
}

.loginComp input {
  width: 80%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #1bc53b;
  color: #343A40;
  height: 30px;
  padding-left: 5px;
  font-size: small;
}

.loginComp input:hover {
  border-width: 0 0 2px;
  border-color: #78c486;
}

.loginComp input:focus {
  border-width: 0 0 2px;
  border-color: #343A40;
}


.loginComp button {
  width: 80%;
  border-radius: 2px solid #3949AC;
  background-color: #3949AC;
  border-width: 0px;
  color: #ffffff;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
}

.loginComp button:hover {
  width: 80%;
  border-radius: 2px solid #3F51B5;
  background-color: #3F51B5;
  border-width: 0px;
  color: #ffffff;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
}

.logout {
  text-decoration: none;
  color: #94a4a2;
}

.logout:hover {
  text-decoration: none;
  color: #c1c6ca;
  cursor: pointer
}


.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}


.backstyle {
  background-image: url('../images/login/homepage.png');
  width: 100vw;
  height: 100vh;
}

.copywrite-bg {
  background-color: #0a4538;
}

ul p {
  color: #A5C6BD;
  font-size: 15px;
}

.logoComp {
  padding-top: 2rem;
  padding-left: 7rem;
  z-index: -1;
}